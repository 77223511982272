<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col sm="4" class="pr-0">
          <b-form-input v-model="salePrice" type="number" title="Цена на сайте"></b-form-input>
        </b-col>
        <b-col sm="3" class="pr-0 pl-0"><b-form-input disabled type="number" v-model="dohod" title="Доход"></b-form-input></b-col>
        <b-col sm="2" class="pr-0 pl-0">
          <b-form-input  type="number" v-model="komsaPercent" title="% комсы вб"></b-form-input></b-col>
        <b-col sm="2" class="pr-0 pl-0"
        ><b-icon icon="exclamation-circle-fill" class="ml-1"></b-icon></b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-badge href="#" size="sm" variant="primary" @click="setAll(item)" class="mr-2">Применить для всех</b-badge>
          <b-badge href="#" size="sm" variant="success" @click="setToWbAllModel()" class="mr-2">Загрузить</b-badge>
        </b-col>
      </b-row>
    </b-col>
  </b-row>


</template>
<script>
export default {
  props: ["item"],
  data: () => ({
    salePrice: 0,
    komsaPercent: 7
  }),
  methods: {
    getBgClass: function(value) {
      if (value > 0) return 'bg-success'
      if (value <= 0) return 'bg-danger'
    },
    setAll() {
      this.$emit('setall', {model: this.item.model, salePrice:this.salePrice, dohod: this.dohod})
    },
    setToWbAllModel() {
      console.log({model: this.item.model, salePrice:this.salePrice, dohod: this.dohod, seller: this.item.seller})
      this.$emit('setToWbAllModel', {model: this.item.model, salePrice:this.salePrice, dohod: this.dohod, seller: this.item.seller})
    }
  },
  mounted() {
this.salePrice = this.item.salePrice
  },
  computed: {
    dohod: function () {
      return  Math.ceil(this.salePrice - (this.salePrice/100)*this.komsaPercent - this.item.arts[0].zakup-55)
    }
  },
  watch: {
  }
}
</script>

<style scoped>
input[type="text"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef!important;
  opacity: 1;
}
</style>