<template>
    <b-row>
      <b-col xl="12">
        <b-table
            small
            bordered
            striped
            hover
            :items="arts"
            :fields="fields"
        >
          <template #table-colgroup="scope">
            <col
                v-for="field in scope.fields"
                :key="field.key"
                :style=calcColWidth(field.key)
            >
          </template>
          <template #cell(vipName)="{item}">
            <a :href="'https://www.wildberries.ru/catalog/' + item.art_wb + '/detail.aspx?targetUrl=XS'" target="_blank">
              {{item.radius}} {{item.dio}} | {{item.art_wb}}</a>
            <Ostatki :barcode="item.barcode" :artwb="item.art_wb" :key="'ostatki-'+item.barcode" />
          </template>
          <template #cell(salePrice)="{item}">
            <b-row>
              <b-col sm="4" class="pr-0">
                <b-form-input v-model="item.salePrice" type="number" title="Цена на сайте"></b-form-input>
              </b-col>
              <b-col sm="3" class="pr-0 pl-0">
                <div class="ml-1" title="Доход">{{item.dohod}}</div>
              </b-col> </b-row>
            <b-row>
              <b-col>
                <b-badge href="#" size="sm" variant="success" @click="setToWbArtWb(item)" class="mr-2">Загрузить</b-badge>
              </b-col>
            </b-row>

<!--          wb: <b-form-input size="sm" v-model="item.salePrice" placeholder=""></b-form-input>   доход:{{item.dohod}} закуп: {{item.zakup}}-->
          </template>
        </b-table>
      </b-col>
    </b-row>
</template>
<style scoped>
input[type="text"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
<script>
import Ostatki from "@/components/products/cols/Ostatki";
export default {
  props: ["arts"],
  components:{Ostatki},
  name: "DohodAndPrice",
  data: () => ({
    fields: [
      {key: "vipName", sortable: true, label: "Товар"},
      {key: "salePrice", sortable: true, label: "Текущая цена"},
      {key: "sales", sortable: true, label: "Продажи"},
      {key: "conk", sortable: true, label: "Конкуренты"},
      {key: "actions", sortable: false, label: "Действия"},
    ]
  }),
  methods: {
    setToWbArtWb(item) {
      const {art_wb, seller, salePrice:needPrice} = item

      fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/report/setToWbArtWb`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({art_wb, seller, needPrice})
      })
          .then(resp => {
            if (resp) alert('добавлен')
          })
     },
    calcColWidth(key) {
      if (key === 'salePrice') return "width:130px"
      if (key === 'vipName') return "width:120px"
      if (key === 'sales') return "width:100px"
      if (key === 'conk') return "width:100px"
      if (key === 'actions') return "width:80px"
      // return "{ width: field.key === 'sales' ? '120px' : '180px' }"
      // return "width:80px"
    },
    getArtClass: function(value) {
      if (value>0) return 'text-success'
      if (value<=0) return 'text-danger'
    }
  },
  mounted() {
    // console.log(this.arts)
    // fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/report/dohodAndPrice/${this.barcode}`, {
    //   method: 'GET',
    //   mode: 'cors',
    //   cache: 'no-cache',
    //   credentials: 'same-origin',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'dildo-token': JSON.parse(localStorage.getItem("dildo-token")),
    //   }
    // })
    //     .then(resp => {
    //       return resp.json()
    //     })
    // .then(resp => {
    //   this.prices = resp
    // })
    // .catch(err => {
    //   console.log(err)
    // })

  }
}
</script>