<template>
  <b-container class="container-fluid">
    <b-row>
      <b-col lg="12">
        <b-row class="mt-2">
          <b-col lg="12">
            <b-overlay :show="isLoadingStat" rounded="lg">
            <b-card no-body class="p-1">
              В нашей бд: всего: {{ stats.vip }}, с остатками: {{ stats.vipOnSale }},
              карточек WB: {{ stats.wb }}, транслируем: {{stats.wbOnSale}} <br>
              На WB: В продаже: all: {{stats.wbFactip+stats.wbFactooo}}, ip:{{stats.wbFactip}}, ooo:{{stats.wbFactooo}}
              <br>
<!--              Карточек: all:{{stats.wbFactCardsip+stats.wbFactCardsooo}}, ip: {{stats.wbFactCardsip}}, ooo: {{stats.wbFactCardsooo}}<br>-->
              В очереди на изменение цен: {{ stats.queueCreated }} | с ошибками: {{stats.queueError}}
            </b-card>
            </b-overlay>
          </b-col>
<!--          <b-col lg="6" >-->
<!--            <b-form-group-->
<!--                label=""-->
<!--                label-for="filter-input"-->
<!--                label-align-sm="left"-->
<!--                label-size="lg"-->
<!--                class="mt-2"-->
<!--            >-->
<!--              <b-input-group size="sm">-->
<!--                <b-form-input-->
<!--                    id="filter-input"-->
<!--                    v-model="filter"-->
<!--                    type="search"-->
<!--                    size="sm"-->
<!--                    placeholder="диоптрии, артикул, шк"-->
<!--                ></b-form-input>-->

<!--                <b-input-group-append>-->
<!--                  <b-button :disabled="!filter" @click="filter = ''">очистить</b-button>-->
<!--                </b-input-group-append>-->
<!--              </b-input-group>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-form-group label="" label-for="tags-component-select">
              <b-form-tags
                  id="tags-component-select"
                  v-model="selModels"
                  size="sm"
                  class="mb-1"
                  add-on-change
                  no-outer-focus
                  @tag-state="recalcTotalRows"
                  @input="recalcTotalRows"
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                  <b-form-select
                      v-bind="inputAttrs"
                      v-on="inputHandlers"
                      :disabled="disabled || availableOptions.length === 0"
                      :options="availableOptions"
                      size="sm"
                  >
                    <template #first>
                      <!-- This is required to prevent bugs with Safari -->
                      <option disabled value="">Выбрать модель...</option>
                    </template>
                  </b-form-select>
                  <ul v-if="tags.length > 0" class="list-inline d-inline-block mt-2" style="margin-bottom: 0">
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                          @remove="removeTag(tag)"
                          :title="tag"
                          :disabled="disabled"
                          variant="primary"
                      >{{ tag }}</b-form-tag>
                    </li>
                  </ul>
                </template>
              </b-form-tags>
            </b-form-group>
          </b-col>
          <b-col lg="4">
                        sc
          </b-col>
          <b-col lg="4">
            <b-card no-body class="p-1">
asdf
            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-row>


    <div class="row mt-4">
      <div class="col-12 div-table">
        <b-table
            fixed
            responsive
            bordered
            sticky-header
            small
            head-variant="light"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            :items="filterModel"
            :fields="fields"
            :busy="isLoading"
            :tbody-tr-class="rowClass"
            :current-page="currentPage"
            :per-page="perPage"
            stacked="md"
            caption-top
        >
          <template #table-colgroup="scope">
            <col
                v-for="field in scope.fields"
                :key="field.key"
                :style=calcColWidth(field.key)
            >
          </template>

          <template #table-caption>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                size="sm"
                class="my-0"
                align="right"
            ></b-pagination>
          </template>

          <template #table-busy>
            <div class="text-center">
              <b-spinner variant="primary" label="loading..."></b-spinner>
            </div>
          </template>
          <template #cell(name)="row">
            <a href="#" @click.prevent="row.toggleDetails">{{ row.item.model }} | {{row.item.seller}}</a><br>
            <span title="Карточек в продаже / Карточек всего" @click.prevent="row.toggleDetails">{{row.item.allQtyCardWithQty}} / {{row.item.allQtyCard}} {{ row.detailsShowing ? '(свернуть)' : '(все диоптрии)'}}</span>
          </template>
          <template #cell(prices)="row">
<price-box :item="row.item" @setall="setAll($event)" @setToWbAllModel="setToWbAllModel($event)" />
<!--            <b-badge href="#" variant="primary" @click="row.toggleDetails">{{ row.detailsShowing ? 'Hide' : 'По диоптриям'}}</b-badge>-->

          </template>
<!--          <template #cell(sales)="{item}">-->
<!--            <b-row>-->
<!--              <b-col xl="6" lg="12">-->
<!--                <div v-for="wb of item.sales.wb" :key="wb.art_wb"><a href="#">{{ wb.art_wb }}</a> {{wb.curMonth}}-->
<!--                  <b-icon-arrow-up class="text-success" v-if="wb.curMonth>wb.lastMonth"></b-icon-arrow-up>-->
<!--                  <b-icon-arrow-down class="text-danger" v-else></b-icon-arrow-down>-->
<!--                  <small>{{wb.lastMonth}}</small></div>-->
<!--              </b-col>-->
<!--              <b-col xl="6" lg="12">-->
<!--                <div v-for="ozon of item.sales.ozon" :key="ozon.art_wb"><a href="#">{{ ozon.ozon_id }}</a> {{ozon.curMonth}} <small>{{ozon.lastMonth}}</small></div></b-col>-->
<!--            </b-row>-->
<!--          </template>-->

<!--          <template #cell(actions)="row">-->
<!--            -->

<!--          </template>-->
          <template #row-details="row">
<!--            {{row.item.arts}}-->
            <DohodAndPrice :arts="row.item.arts" :key="'dohod-'+row.item.model" />
          </template>


        </b-table>
      </div>
    </div>
  </b-container>
</template>
<style scoped>
input[type="text"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
<script>
import DohodAndPrice from "@/components/products/cols/DohodAndPrice";
import PriceBox from "@/components/products/list2/PriceBox";

export default {
  name: "MainTable",
  components: {PriceBox, DohodAndPrice},
  props: ['type'],
  data: () => ({
    totalRows: 0,
    selModels: [],
    models: ['Air Optix Aqua'],
    selTypes: [],
    currentPage: 1,
    perPage: 100,
    isLoading: true,
    isLoadingStat: true,
    filter: '',
    filterOn: ['attr', 'name'],
    products: [],
    stats: {
      vip: 0,
      vipOnSale: 0,
      wb: 0,
      wbOnSale: 0,
      queueCreated: 0,
      queueError: 0,
      wbFactip: 0,
      wbFactooo: 0
    },
    fields: [
      {key: "name", sortable: true, label: "Товар"},
      {key: "prices", sortable: true, label: "Цены и доход"},
      {key: "sales", sortable: true, label: "Продажи"},
      {key: "ratings", sortable: true, label: "Рейтинг"},
      {key: "actions", sortable: false, label: ""},
    ],
    sorters: {
  qty: false
}
  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    setAll({model, salePrice, dohod}) {
       this.products.filter(el => el.model ===model).forEach(el => {
         el.arts.forEach(els =>{
           els.salePrice=salePrice
           els.dohod=dohod
         })
       })
    },
    setToWbAllModel({model, salePrice, dohod, seller}) {
      fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/report/setToWbAllModel`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({model, salePrice, dohod, seller})
      })
          .then(resp => {
            return resp.json()
          })
          .then(({status}) => {
            if (status) this.alertMsg('Загружаем цены | Успешно', 'Новые цены в очереди на заливку на вб', 'success')
            if (!status) this.alertMsg('Загружаем цены | Ошибка', 'ошибка сервера', 'danger')
          })
          .catch(err => {
            this.alertMsg('Загружаем цены | Ошибка', `ошибка загрузки на сервер: ${err}`, 'danger')
          })
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "yes") return "table-success";
      if (item.status === "up") return "table-info";
    },
    async loadData() {
      const _ = require("lodash")
      this.isLoading = true
      fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/report/prodList/${this.type}`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}})
          .then(resp => resp.json())
          .then(data => {
            if (data.length <= 0) throw "нет товаров";

            const vip = _.chain(JSON.parse(JSON.stringify(data)).filter(e => e.model).map(e => {
              return {...e, smodel: `${e.seller} ${e.model}`}
            }))
                .groupBy("smodel")
                .map((value, smodel) => ({
                  model: value[0].model,
                  smodel: smodel,
                  // attr: '178/180',
                  type: value[0].type,
                  allQtyCard: value.length,
                  allQtyCardWithQty: value.filter(e => e.wb+e.wbPssp>0).length,
                  // sumQty: value[0].spbIn+value[0].spbExport+value[0].mskIn+value[0].mskExport,
                  arts: value,
                  salePrice: value[0].salePrice,
                  dohod: value[0].dohod,
                  komsaPercent: (value[0].komsaPercent) ? value[0].komsaPercent : 5,
                  seller: value[0].seller
                }))
                .value()

            this.products = _.sortBy(vip.filter(e => e.type==this.type).reverse(), 'model', 'desc')
            // this.products = data;
            this.totalRows = this.filterModel.length
            this.currentPage = 1
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
    },
    calcColWidth(key) {
      if (key === 'name') return "width:170px"
      if (key === 'prices') return "width:160px"
      if (key === 'sales') return "width:130px"
      if (key === 'ratings') return "width:120px"
      if (key === 'actions') return "width:40px"
      // return "{ width: field.key === 'sales' ? '120px' : '180px' }"
      // return "width:80px"
    },
    onFiltered(filteredItems) {
      // console.log('on filter', filteredItems.length)
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    recalcTotalRows() {
      this.totalRows = this.filterModel.length
      this.currentPage = 1
    },
    async getStats() {
      this.isLoadingStat=true
      fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/report/getStats`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}})
          .then(resp => resp.json())
      .then(resp => {
        if (resp.status=='ok') {
          this.stats = resp.data
          this.isLoadingStat=false
        }
      })
    }
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.getStats()
    setInterval(() => this.getStats(), 60000)

    this.loadData(this.type)

  },
  computed: {
    availableOptions() {
      return this.models.filter(opt => this.products.indexOf(opt) === -1)
    },
    filterModel() {
      let pr = this.products
      // if (this.sorters.qty) pr = pr.filter(e => e.sumQty>0)
      if (this.selModels.length==0) return pr
      return pr.filter(e => this.selModels.includes(e.model))
    },
  },
  watch: {
    'filterModel': function () {
      this.models = Array.from(new Set(this.products.map(e => e.model))).sort()
    },
    'type' : function () {
      this.loadData(this.type)
    },
    'sorters.qty': function () {
      console.log('go sorts')
    },
    'products.salePrice': function() {
      console.log('fd')
    }
  }

};
</script>

<style>
option {
  font-size: 10px;
}
.table-cur {
  background-color: rgba(183, 217, 254, 0.5) !important;
}
.table-primary {
  background-color: rgba(183, 255, 200, 0.4) !important;
}
.table-sec {
  background-color: rgba(255, 217, 254, 0.45) !important;
}

.div-table {
  font-size: 12px !important;
}

/*new*/
/* Busy table styling */
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}
.custom-control-label {
  padding-top: 5px;
}
.form-control-sm{ padding: 0.25rem 0.5rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>
