<template>
    <b-row class="mt-2">
      <b-col lg="2">
        <left-col @changetype="changetype($event)"/>
      </b-col>
      <b-col>
        <main-col :type="type" />
      </b-col>
    </b-row>
</template>

<script>
import leftCol from "@/components/layout/leftCol";
import mainCol from "@/components/layout/mainCol"

export default {
  name: 'App',
  data: () => ({
    type: 'Прозрачные'
  }),
  components: {
    leftCol, mainCol
  },
  methods: {
    changetype (type) {
      this.type = type
    }
  },
 async mounted() {
   const dildoToken = this.$cookies.get("dildo-token");
   if (!dildoToken) this.$router.push({path: "/login"});
   if (dildoToken) {
     // await this.getData()
   }
  }
}
</script>

<style>
body {
  background-color: #f2f2f2!important;
}
</style>
