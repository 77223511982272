<template>
  <b-container fluid class="main-container">

<b-row>

  <b-col>
    <main-table :type="type" />
  </b-col>

</b-row>


  </b-container>
</template>

<script>
import MainTable from "@/components/products/MainTable";

export default {
  name: 'mainCol',
  props: ['type'],
  components: {
    MainTable
  },
 async mounted() {

  }
}
</script>

<style scoped>
.col {
  border: 1px solid #e7e7e7;
}
.main-container {
  background-color: #fff!important;
}
</style>
